import React from 'react';
import PropTypes from 'prop-types';
import CustomLink from 'components/ui/link';
import * as Styled from './styled';

const CategoryCard = ({ title, url, icon, description }) => (
  <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
    <Styled.CardClick href={url}>
      <Styled.Category>
        <Styled.Card className="card">
          <div className="card-body">
            <div className="items">
              <img src={icon} alt="icon" className="img-fluid d-block" title={title} />
              <Styled.CategoryTitle className="text-center">
                <h2>{title}</h2>
                <p className="description text-center">{description}</p>
              </Styled.CategoryTitle>
            </div>
            <CustomLink tag="p" withArrow>
              Learn more
            </CustomLink>
          </div>
        </Styled.Card>
      </Styled.Category>
    </Styled.CardClick>
  </div>
);

export default CategoryCard;

CategoryCard.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
