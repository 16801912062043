import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import 'css/entypo.css';
import { StyledLink } from 'components/ui/link';

export const CardClick = styled.a`
  display: block;
  &:hover {
    text-decoration: none;
    ${StyledLink} {
      &::after {
        transform: translateX(8px);
      }
    }
  }
`;

export const Category = styled.div`
  &:hover {
    text-decoration: none;
  }
  margin: 0 auto;
  ${media.desktopWide} {
    min-width: 290px;
  }
  ${media.tablet} {
    min-width: 100%;
    min-height: auto;
  }
`;

export const CategoryTitle = styled.div`
  color: #000;
  margin-top: 26px;
  h2 {
    font-size: 20px;
    margin-bottom: 1rem;
    font-weight: 900;
    line-height: normal;
    ${media.tablet} {
      font-size: 18px;
    }
  }
  .description {
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    margin-bottom: 0;
    ${media.tablet} {
      font-size: 14px;
      line-height: 24px;
    }
  }
`;

export const Card = styled.div`
  width: 100%;
  border: 1px solid #e4e5e8;
  border-radius: 4px;
  transition: 0.15s;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    width: 100%;
  }
  &:hover {
    background-color: #ffe478;
    box-shadow: 0px 0px 8px #00000029;
  }

  & i,
  i:hover {
    color: rgb(0, 105, 217);
  }
  .card-body {
    display: flex;
    flex-direction: column;
    margin: 5px 42px;
    height: 307px;
    justify-content: space-evenly;
    padding: 0;
    ${media.tablet} {
      padding: 30px 0;
      height: 250px;
    }
    ${media.mobile} {
      height: auto;
    }
    .items {
      display: flex;
      flex-direction: column;
      justify-content: center;
      & img {
        max-width: min-content;
        margin: 0 auto;
      }
    }
    ${StyledLink} {
      font-size: 14px;
      text-align: center;
      margin-bottom: 0;
      ${media.tablet} {
        display: none;
      }
    }
  }
`;
