import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import map from 'lodash/map';
import Layout from 'layout';
import { media } from 'theme/breakpoints';
import ArticlePreview from 'components/help/helpArticlePreview';
import CategoryCard from 'components/help/categoryCard';
import { all } from 'data/helpArticles';
import getstartedicon from 'img/help/helppage/getstartedicon.svg';
import integrationsicon from 'img/help/helppage/integrationsicon.svg';
import developericon from 'img/help/helppage/developericon.svg';
import privacyicon from 'img/help/helppage/privacyicon.svg';
import producticon from 'img/help/helppage/producticon.svg';
import StartUsing from 'components/ui/startUsing';
import SearchBar from 'components/help/searchbar/SearchBar';
import ContactWithUs from 'components/ui/contactwithus';

const HeaderSection = styled.section`
  background-color: #fff;
  color: #000;
  padding: 180px 0 1.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    margin-bottom: 32px;
    text-align: center;
  }
  p {
    margin-top: 0;
    margin-bottom: 48px;
    font-size: 20px;
    width: 100%;
    text-align: center;
    font-weight: 500;
    ${media.tablet} {
      font-size: 18px;
      line-height: 23px;
      width: 85%;
      margin: 0 auto 36px;
    }
  }
  ${media.desktopWide} {
    padding: 270px 0 4rem 0;
  }

  ${media.tablet} {
    padding: 7rem 0 1.5rem 0;
    h1 {
      font-size: 28px;
      margin-bottom: 24px;
    }
  }
  .react-autosuggest__suggestions-container--open {
    max-height: 363px;
    overflow-y: auto;
    width: 488px;
    box-shadow: 0px 0px 20px #00000029;
    border-radius: 4px;
    position: absolute;
    top: 0;
    right: 50%;
    transform: translate(50%, 52px);
    z-index: 15;
  }
  ${media.tablet} {
    .react-autosuggest__suggestions-container--open {
      width: 100%;
    }
  }
  .react-autosuggest__suggestion {
    list-style: none;
    display: list-item;
  }
  .react-autosuggest__suggestions-list {
    padding: 0;
    margin: 0;
  }

  .react-autosuggest__suggestion--highlighted .styledbox {
    background-color: #f6f8f9;
  }
`;
const ContentSection = styled.section`
  background-color: #fff;
  padding-top: 5rem;
  padding-bottom: 0;
  ${media.tablet} {
    padding-top: 3rem;
  }
  .subtitle {
    font-weight: 900;
    margin-bottom: 1rem;
    ${media.tablet} {
      font-size: 24px;
      margin-bottom: 0;
      line-height: normal;
    }
  }
  .articlesContainer {
    display: flex;
    ${media.tablet} {
      flex-direction: column;
    }
    .listArticles {
      width: 50%;
      margin-right: 64px;
      list-style: none;
      padding: 0;
      ${media.tablet} {
        width: 100%;
        margin-right: 0;
      }
      li {
        &:nth-child(3) div {
          border-bottom: none;
        }
        div {
          border: none;
          padding: 32px 0;
          border-bottom: 1px solid #e4e5e8;
          border-radius: 0;
          ${media.tablet} {
            padding: 24px 0;
          }
          &:hover {
            box-shadow: none;
          }
        }
      }
    }
    .contactquestion {
      background-color: #ffe478;
      min-height: 449px;
      padding: 73px 116px 73px 3rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-basis: 50%;
      ${media.desktopWide} {
        padding: 73px 86px 73px 3rem;
      }
      ${media.tablet} {
        width: 100%;
        flex-basis: auto;
        margin: 80px 0 0;
        padding: 30px 24px;
        justify-content: space-evenly;
        min-height: auto;
        text-align: center;
      }
      ${media.mobile} {
        text-align: left;
      }
      h2 {
        font-size: 32px;
        line-height: 42px;
        font-weight: 900;
        ${media.tablet} {
          font-size: 24px;
          line-height: 34px;
          padding: 0;
        }
      }
    }
  }
`;

const HelpPage = ({ articles }) => {
  const renderArticles = () => {
    if (!articles || articles.length === 0) return false;

    const list = map(articles, (el) => (
      <li key={el.canonical}>
        <ArticlePreview key={el.link} {...el} />
      </li>
    ));
    return (
      <div className="articlesContainer mb-3 feature-list feature-list-sm col-12">
        <ul className="listArticles">{list.slice(0, 3)}</ul>
        <div className="contactquestion">
          <h2>Didn’t you find the answer to your question?</h2>
          <ContactWithUs />
        </div>
      </div>
    );
  };

  return (
    <Layout
      metaTitle="Help Center | Learn More About Session Replays"
      canonical="/help/"
      metaDescription="Find answers to your questions about session replays. Learn how to get the most from LiveSession and integrate the tool with other apps."
    >
      <HeaderSection>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-10">
              <h1>How can we help?</h1>
              <p>Learn how the product works and how to use it</p>
              <SearchBar />
            </div>
          </div>
        </div>
      </HeaderSection>
      <ContentSection>
        <div className="container">
          <div className="row justify-content-center">
            <CategoryCard
              title="Get started"
              url="/help/get-started/"
              icon={getstartedicon}
              description="Learn how to install LiveSession and start using it."
            />
            <CategoryCard
              title="Product"
              url="/help/product/"
              icon={producticon}
              description="Learn how LiveSession works."
            />
            <CategoryCard
              title="Developer"
              url="/help/developer/"
              icon={developericon}
              description="Here you will find answers to your technical questions."
            />
            <CategoryCard
              title="Privacy"
              url="/help/privacy/"
              icon={privacyicon}
              description="Here you will find out how to keep your users privacy."
            />
            <CategoryCard
              title="Integrations"
              url="/help/integrations/"
              icon={integrationsicon}
              description="Find out which applications you can integrate with and how."
            />
          </div>
          <section className="row justify-content-center">
            <div className="col-12 mb-3">
              <h2 className="subtitle">Popular Articles</h2>
            </div>
            {renderArticles(3)}
          </section>
        </div>
        <StartUsing />
      </ContentSection>
    </Layout>
  );
};

export default () => <HelpPage articles={all} />;

HelpPage.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      metaTitle: PropTypes.string.isRequired,
      metaDescription: PropTypes.string.isRequired,
      canonical: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
